<template>
  <b-modal
    id="create-project-modal"
    ref="create-project-modal"
    class="pt-2"
    size="md"
    :modal-class="`modal-${variant}`"
    :title="title"
    ok-only
    :ok-title="$t('Accept')"
    @close="$emit('close')"
    @hide="() => { resetForm(); $emit('hide'); }"
  >
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent>
        <!-- Select Company -->
        <div v-if="!companyId && owner">
          <b-form-group :label="$t('Company')">
            <v-select
              v-model="form.companyUid"
              :options="companies"
              :reduce="(item) => item.value"
              label="text"
              :placeholder="$t('Select a Company')"
            >
              <template #option="{ text }">
                <b-avatar
                  size="30"
                  :text="text.charAt(0)"
                  class="mr-1"
                />
                <span> {{ text }}</span>
              </template>
            </v-select>
          </b-form-group>
        </div>
        <validation-provider
          #default="{ errors }"
          name="first name"
          rules="required"
        >
          <b-form-group
            :label="$t('Project Title')"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="FolderIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="form.name"
                :placeholder="$t('Project Title')"
                :state="errors.length > 0 ? false:null"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
        <!-- Select Owner -->
        <div v-if="companyId && !owner">
          <b-form-group
            v-if="!project"
            :label="$t('Project Owner')"
          >
            <user-search
              v-model="form.ownerUid"
              :company-id="companyId"
            />
          </b-form-group>
        </div>

        <b-form-group
          :label="$t('Project Description')"
        >
          <b-input-group class="input-group-merge">
            <b-form-textarea
              v-model="form.description"
              rows="8"
              :placeholder="$t('Project Description')"
            />
          </b-input-group>
        </b-form-group>
      </b-form>
    </validation-observer>
    <template #modal-footer="{ }">
      <b-row>
        <b-col
          md="12"
          class="d-flex justify-content-end"
        >
          <b-button
            variant="primary"
            :disabled="isProcessing"
            @click="handleOk"
          >
            <b-spinner
              v-show="isProcessing"
              small
            />
            <span v-if="form.uuid">
              {{ $t('Update') }}
            </span>
            <span v-else>
              {{ $t('Create') }}
            </span>
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>
<script>
import {
  BFormGroup, BFormInput, BForm, BInputGroup, BInputGroupPrepend, BFormTextarea, BButton, BSpinner, BAvatar,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import useApollo from '@/plugins/graphql/useApollo'
import UserSearch from '@/views/common/components/UserSearch.vue'
import vSelect from 'vue-select'
import { getUserData } from '@/auth/utils'

const initialForm = () => ({
  uuid: null,
  name: '',
  description: '',
  companyUid: null,
})

export default {
  components: {
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BSpinner,
    BAvatar,
    vSelect,
    UserSearch,
  },
  props: {
    companyId: {
      type: [Number, String],
      default: () => null,
    },
    project: {
      type: Object,
      default: () => null,
    },
    owner: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      self: getUserData(),
      isProcessing: false,
      form: {
        ...initialForm(),
      },
      required,
      companies: [],
    }
  },
  computed: {
    title() {
      return this.project ? this.project?.name : this.$t('Create Project')
    },
    variant() {
      return this.project ? 'primary' : 'secondary'
    },
  },
  watch: {
    project: {
      handler(newValue) {
        if (newValue) {
          this.form = { ...newValue }
        } else {
          this.form = { ...initialForm() }
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.owner) this.getMyCompaniesWithRoles()
  },
  methods: {
    handleOk() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const formData = { ...this.form }
          if (!formData.companyUid) { formData.companyUid = this.companyId }

          if (!this.companyId && this.owner) {
            formData.ownerUid = this.owner
          } else formData.ownerUid = this.self.uuid
          this.isProcessing = true
          if (formData.uuid) {
            formData.id = formData.uuid
            this.handleUpdate(formData)
            return
          }
          useApollo.project.storeProject(formData).then(response => {
            this.$emit('onSave', response.data.createProject.data)
            this.$emit('close')
            this.showSuccessMessage({
              data: {
                message: 'Project successfully created',
              },
            })
          }).catch(error => {
            this.showErrorMessage(error)
          }).finally(() => {
            this.isProcessing = false
            this.$bvModal.hide('create-project-modal')
          })
        }
      })
    },
    handleUpdate(formData) {
      useApollo.project.updateProject(formData).then(() => {
        this.$emit('onSave')
        this.$emit('close')
        this.showSuccessMessage({
          data: {
            message: 'Project successfully updated',
          },
        })
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
        this.$bvModal.hide('create-project-modal')
      })
    },
    getMyCompaniesWithRoles() {
      useApollo.users.getMyCompaniesWithRoles().then(response => {
        this.companies = response.data.me.companyRoles.data.filter(role => role.role.name === 'owner')
          .map(data => ({
            text: data.company.name,
            value: data.company.uuid,
          }))
      })
    },
    resetForm() {
      this.form = {
        uuid: null,
        name: '',
        description: '',
        companyUid: null,
      }
    },
  },

}
</script>
